import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

export default function IsGPM(props) {
  const { open, caption, setOpen } = props;
  return (
    <SweetAlert
      info
      title="Info!"
      show={open}
      onConfirm={() => {
        setOpen(false);
        window.open("http://sirs.grahaperdanamedika.com", "_self");
      }}
    >
      <p className="sweet-alert-text">{caption ? caption : "Informasi..."}</p>
    </SweetAlert>
  );
}
