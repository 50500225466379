import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-up";
import { Button } from "reactstrap";
import * as Icons from "react-feather";
import classnames from "classnames";
import IsGPM from "layouts/alert/IsGPM";
import { getUser, urlAPI } from "utility/Common";
import Axios from "axios";
import { useDispatch } from "react-redux";

const Footer = (props) => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  const [open, setOpen] = useState(false);
  const [caption, setCaption] = useState("");
  const dispatch = useDispatch();

  const fetchDateMovingToGPM = () => {
    Axios.get(urlAPI + "/v1/common/movingGPM")
      .then((response) => {
        const result = response?.data?.result;
        const timestampNow = new Date();
        if (timestampNow > Number(result)) {
          setOpen(true);
          setCaption(
            "Untuk User SBU " +
              getUser()?.sbu +
              " Menggunakan SIRS PT GPM, tidak lagi menggunakan SIRS PT PGM. Klik OK untuk diarahkan ke SIRS PT GPM."
          );
        } else {
          setOpen(false);
          setCaption("");
        }
      })
      .catch((err) => {
        dispatch({
          type: "FILL_GAGAL",
          gagal: true,
          pesan: err?.response?.data?.message,
        });
      });
  };

  useEffect(() => {
    if (
      getUser()?.sbu === "KALIMANTAN" ||
      getUser()?.sbu === "PPK1" ||
      getUser()?.sbu === "WA"
    ) {
      fetchDateMovingToGPM();
    } else {
      setOpen(false);
      setCaption("");
    }
  }, []);

  return (
    <>
      <IsGPM open={open} caption={caption} setOpen={(e) => setOpen(e)} />
      <footer
        className={classnames("footer footer-light", {
          "footer-static":
            props.footerType === "static" ||
            !footerTypeArr.includes(props.footerType),
          "d-none": props.footerType === "hidden",
        })}
      >
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
            Developed by
            <a
              href="http://petrograhamedika.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bagian SIRS - PT Petro Graha Medika
            </a>
          </span>
          <span className="float-md-right d-none d-md-block">
            <span className="align-middle">Hand-crafted & Made with</span>{" "}
            <Icons.Heart className="text-danger" size={15} />
          </span>
        </p>
        {props.hideScrollToTop === false ? (
          <ScrollToTop showUnder={160}>
            <Button color="primary" className="btn-icon scroll-top">
              <Icons.ArrowUp size={18} />
            </Button>
          </ScrollToTop>
        ) : null}
      </footer>
    </>
  );
};

export default Footer;
